<script>
import Vue from "vue";
export default {
  name: "busStopProgress",
  data() {
    return {
      mode: null,
      users: JSON.parse(localStorage.getItem("alcotrackerparentuser")),
      stops: [],
      mark: {}
    };
  },
  props: ["vdata","sdata"],
  beforeMount() {
      Vue.axios
        .get(
          "https://robopower.xyz/app/vehicleroutes/getroutestops2?routeId=" +
            this.sdata.routeId
        )
        .then(response => {
          this.stops = response.data;
          this.stops.sort((a, b) => a.stopid - b.stopid);
          this.draw();
        });
    // });
  },
  created() {
    setInterval(() => {
      this.draw();
    }, 10000);
    // Vue.axios
    //   .get(
    //     "https://robopower.xyz/app/v2data/getlatestpingv2?devId=" +
    //       this.data.deviceId
    //   )
    //   .then(response => {
    //     let that = this;
    //     var lati = parseFloat(response.data.lat);
    //     var long = parseFloat(response.data.lon);
    //     var marker = {
    //       lat: lati,
    //       lng: long
    //     };
    //     that.mark = marker;
    //     that.draw();
    //   });
    // this.pusher = new Pusher("ca5d067792cdfce54eca", {
    //   encrypted: true,
    //   cluster: "mt1"
    // });
    // let that = this;
    // this.channel = this.pusher.subscribe(this.data.deviceId);
    // this.channel.bind("my-event", function(data) {
    //   var lati = parseFloat(data.lat);
    //   var long = parseFloat(data.lon);
    //   var marker = {
    //     lat: lati,
    //     lng: long
    //   };
    //   that.mark = marker;
    //   that.draw();
    // });
  },
  beforeUnmount() {
    // this.channel = this.pusher.unsubscribe(this.data.deviceId);
    // this.channel.unbind("my-event");
  },
  beforeDestroy() {},
  methods: {
    onRefreshbar() {
      this.draw();
    },
    distance(lat1, lon1, lat2, lon2, unit) {
      if (lat1 == lat2 && lon1 == lon2) {
        return 0;
      } else {
        var radlat1 = (Math.PI * lat1) / 180;
        var radlat2 = (Math.PI * lat2) / 180;
        var theta = lon1 - lon2;
        var radtheta = (Math.PI * theta) / 180;
        var dist =
          Math.sin(radlat1) * Math.sin(radlat2) +
          Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
          dist = 1;
        }
        dist = Math.acos(dist);
        dist = (dist * 180) / Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit == "K") {
          dist = dist * 1.609344;
        }
        if (unit == "N") {
          dist = dist * 0.8684;
        }
        return dist;
      }
    },
    draw() {
      Vue.axios
        .get(
          "https://robopower.xyz/app/v2data/getlatestpingv22?cbid=" +
            this.vdata.cbid
        )
        .then(response => {
          var signal = response.data;
          var lat1 = parseFloat(signal.lat).toFixed(3);
          var lon1 = parseFloat(signal.lon).toFixed(3);
          const currentStop = this.stops.find(position => {
            var lat2 = parseFloat(position.lat);
            var lon2 = parseFloat(position.lon);
            return this.distance(lat1, lon1, lat2, lon2, "K") < 0.2;
          });
          if (currentStop) {
            this.stops = this.stops.map(stop => {
              stop.done = false;
              stop.reached = false;
              if (parseInt(stop.stopid) < parseInt(currentStop.stopid)) {
                stop.done = true;
              }
              if (parseInt(stop.stopid) == parseInt(currentStop.stopid)) {
                stop.reached = true;
              }
              return stop;
            });
          }
        });
    }
  }
};
</script>

<template>
  <div>
    <v-card flat style="background-color:teal">
      <v-card-text style="color: white;"
        >Bustop Routes
        <v-btn @click="onRefreshbar" class="end" rounded dark color="success"
          >Refresh
        </v-btn>
      </v-card-text>
    </v-card>
    <div class="button"></div>
    <v-flex xs12>
      <v-card color="#033" class="white--text">
        <v-row>
          <v-col cols="4">
          <v-flex xs4 sm4 md4 class="ml-2">
            <v-card-title primary-title>
              
              <div
                :class="{
                  caption: $vuetify.breakpoint.smAndDown,
                  'body-2': $vuetify.breakpoint.mdAndUp
                }"
              >
                Routes
              </div>
            </v-card-title>
          </v-flex>
          <v-flex xs4 sm4 md4 class="my-auto">
            <v-card-title primary-title>
              <div>{{ this.sdata.routeId }}</div>
            </v-card-title>
          </v-flex>
          <!-- <v-flex xs3 sm3 md3 class="my-auto"> -->
            <v-card-title primary-title>
              <div
                :class="{
                  caption: $vuetify.breakpoint.smAndDown,
                  'body-2': $vuetify.breakpoint.mdAndUp
                }"
              >
                Hyd to KKD
              </div>
            </v-card-title>
          <!-- </v-flex> -->


</v-col>
<v-col cols="8">
          <div class="progress">
            <div
              class="step"
              v-for="(stop, index) in stops"
              :class="{ done: stop.done, reached: stop.reached }"
              :key="index"
            >
              <div
                class="step-progress"
                v-if="index !== stops.length - 1"
              ></div>
              <div class="icon-wrapper">
                <svg class="icon icon-checkmark" viewBox="0 0 32 32">
                  <path
                    class="path1"
                    d="M27 4l-15 15-7-7-5 5 12 12 20-20z"
                  ></path>
                </svg>
                <div class="step-text">{{ stop.stopname }}</div>
              </div>
            </div>
          </div>
        </v-col>
          &nbsp; &nbsp; &nbsp;
        </v-row>
        &nbsp;
      </v-card>
    </v-flex>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin-left: 10px;
}
.icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  fill: none;
}

.hidden {
  display: none;
}

.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin: auto;
  margin-top: 70px;
  margin-left: 10px;
}
.step {
  flex-grow: 1;
  position: relative;
}
.white--text {
  opacity: 0.7;
}
.step-progress {
  width: 100%;
  height: 5px;
  background: #e0e0e0;
}
.icon-wrapper {
  text-align: center;
  display: inline-block;
}

.step.done .step-progress:after {
  position: absolute;
  content: "";
  height: 5px;
  width: 0;
  background-color: #2bff56;
  animation: growLine 2s linear forwards;
}

.icon-checkmark {
  position: absolute;
  top: -0.55em;
  left: -0.125em;
  border: 0.125em solid #e0e0e0;
  background: #e0e0e0;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  padding: 0.125em;
  border-radius: 50%;
  transition: all 0.25s linear;
}
.step.done .icon-checkmark,
.step.reached .icon-checkmark {
  background: #2bff56;
  border-color: #2bff56;
}

.icon-checkmark .path1 {
  stroke: #aaa;
  stroke-width: 4;
  stroke-linecap: square;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  fill: transparent;
}
.step.done .icon-checkmark .path1 {
  animation: dash 5s linear forwards;
  stroke: #00403a;
}

.step-text {
  position: absolute;
  letter-spacing: 1px;
  font-weight: bold;
  top: -60px;
  left: -20px;
  color: #e0e0e0;
  margin-top: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  font-size: 10px;
  width: 100px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.step:nth-child(odd) .step-text {
  top: 50px;
  left: -10px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  text-align: left;
}
.step:last-child {
  max-width: 40px;
}
.step:last-child .step-text {
  left: -40px;
  top: 60px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  text-align: left;
}

.step.done .step-text {
  color: #2bff56;
}

.end {
  position: absolute;
  right: 0;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes growLine {
  to {
    width: 100%;
  }
}

@keyframes dropText {
  to {
    padding-top: 1em;
    opacity: 1;
  }
}
</style>
